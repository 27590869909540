import { Container, Typography, Box, Paper } from '@mui/material';

function CV() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 8, mb: 4 }}>
        <Typography variant="h1" gutterBottom>
          Curriculum Vitae
        </Typography>
        <Paper elevation={1} sx={{ p: 3, height: '800px' }}>
          <iframe
            title="CV"
            src="/Chuck_Downing_CV.pdf"
            width="100%"
            height="100%"
            style={{ border: 'none' }}
          />
        </Paper>
      </Box>
    </Container>
  );
}

export default CV; 