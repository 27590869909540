import { Container, Typography, Box, Paper } from '@mui/material';

function Research() {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 8, mb: 4 }}>
        <Typography variant="h1" gutterBottom>
          Research
        </Typography>
        
        <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Working Papers
          </Typography>
          <Box sx={{ ml: 2 }}>
            <Typography variant="body1" paragraph>
              <strong>"Odd Lots & Optics: Manipulation in Response to Scrutiny"</strong> with Bradford Levy, Matthew Phillips, and Eric So
              <ul style={{ margin: '0.5rem 0', paddingLeft: '1.5rem' }}>
                <li>Revising for 2nd round at <em>Journal of Accounting Research</em></li>
                <li>Based on 1st-year summer paper</li>
              </ul>
              <Typography variant="body1" sx={{ mt: 2, ml: 2, color: '#666', fontSize: '0.95rem' }}>
              We study the 2015 introduction of a voluntary disclosure program that focused on the execution quality of equity trades under 100 shares, known as odd lots. The disclosure program was enacted during a period of increased regulatory and media scrutiny of how market makers fulfilled odd lot orders. We show the percentage of odd lot orders that receive price improvement from market makers jumped discontinuously at the outset of the program. This jump was driven by trivially small price improvement given to a larger fraction of orders, and an offsetting reduction in larger price improvement for a small handful of orders. These changes resulted in no material difference in overall execution quality but allowed market makers and brokers to tout high execution quality statistics via their disclosures. Together, our evidence suggests that public scrutiny creates incentives for firms to use mutually reinforcing operational and disclosure changes to manipulate public sentiment.              </Typography>
            </Typography>

          </Box>
        </Paper>

        <Paper elevation={1} sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Research in Progress
          </Typography>
          <Box sx={{ ml: 2 }}>
            <Typography variant="body1" paragraph>
              <strong>"Use of Generative AI to Measure Information Complexity"</strong>
              <br />
              <em>With David Kim and Eric So</em>
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>"Generative AI and Textual Variables in Accounting Research"</strong>
              <br />
              <em>With David Kim</em>
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>"Disclosure, Tax-Based Incentives, and Carbon Emission Outcomes"</strong>
              <br />
              <em>With Zoe Han, Rodrigo Verdi, and Chloe Xie</em>
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default Research; 