import { Container, Typography, Box, Paper, Grid } from '@mui/material';

function Teaching() {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 8, mb: 4 }}>
        <Typography variant="h1" gutterBottom>
          Teaching
        </Typography>

        <Paper elevation={1} sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Teaching Experience
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ ml: 2 }}>
                <Typography variant="body1" paragraph>
                  <strong>Financial Accounting</strong>
                  <br />
                  TA for Professor SP Kothari (Sloan Fellows MBA) 2023
                </Typography>
                <Typography variant="body1" paragraph>
                  <strong>Finance Lab</strong>
                  <br />
                  TA for Professor Gita Rao (MFin and MBA) 2023, 2024
                </Typography>
                <Typography variant="body1" paragraph>
                  <strong>MIT Sloan Generative AI Teaching and Learning Initiative</strong>
                  <br />
                  TA/RA for Professor Eric So 2023-2024
                  <br />
                  <Typography variant="body1" sx={{ mt: 2, color: '#4A4A4A' }}>
                    I developed comprehensive course materials to help Sloan faculty understand and integrate recent advances in generative AI into their teaching. Additionally, I created educational content for MIT Sloan's Teaching and Learning Hub, including the video featured below that demonstrates practical applications of AI in business education.
                  </Typography>
                </Typography>

                {/* Embedded YouTube Video */}
                <Box sx={{ mt: 2 }}>
                  <iframe
                    width="100%"
                    height="500"
                    src="https://www.youtube.com/embed/SzTjGAO6fKo"
                    title="MIT Sloan Generative AI Teaching and Learning Initiative" 
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    style={{
                      maxWidth: '800px',
                      display: 'block',
                      margin: '0 auto'
                    }}
                  ></iframe>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
}

export default Teaching;
