import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  const navButtonStyle = (path) => ({
    fontWeight: isActive(path) ? '500' : '400',
    borderBottom: isActive(path) ? '1px solid white' : '1px solid transparent',
    borderRadius: 0,
    padding: '8px 16px',
    letterSpacing: '0.5px',
    textTransform: 'none',
    fontSize: '1rem', // Increased font size by 50%
    '&:hover': {
      backgroundColor: 'transparent',
      borderBottom: '1px solid rgba(255,255,255,0.7)'
    }
  });

  return (
    <AppBar 
      position="static" 
      elevation={0}
      sx={{ 
        backgroundColor: '#800000',
        borderBottom: '1px solid rgba(255,255,255,0.1)'
      }}
    >
      <Toolbar sx={{ padding: '0.5rem 3rem' }}>
        <Link to="/">
          <img 
            src="/sloanlogo.png" 
            alt="Logo" 
            style={{ 
              height: '80px',
              display: 'block',
              objectFit: 'contain'
            }} 
          />
        </Link>
        <Box sx={{ 
          display: 'flex', 
          gap: 4,
          marginLeft: 'auto'
        }}>
          {['Home', 'Research', 'CV', 'Teaching'].map((item) => (
            <Button
              key={item}
              component={Link}
              to={item === 'Home' ? '/' : `/${item.toLowerCase()}`}
              color="inherit"
              sx={navButtonStyle(item === 'Home' ? '/' : `/${item.toLowerCase()}`)}
            >
              {item}
            </Button>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;