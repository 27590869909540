import { Box, Typography, Container } from '@mui/material';

function Home() {
  return (
    <Container 
      maxWidth="lg" 
      sx={{
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        py: 6
      }}
    >
      <Box sx={{ 
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        gap: 6,
        mb: 5
      }}>
        <Box sx={{ 
          width: { xs: '200px', sm: '250px' },
          height: { xs: '200px', sm: '250px' },
          borderRadius: '50%',
          overflow: 'hidden',
          flexShrink: 0
        }}>
          <img
            src="/chuck-downing-headshot.jpg"
            alt="Chuck Downing"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
        </Box>
        
        <Box sx={{ 
          textAlign: { xs: 'center', sm: 'left' },
          maxWidth: '700px'
        }}>
          <Typography 
            variant="h1" 
            sx={{ 
              fontSize: { xs: '2.5rem', sm: '3.5rem' },
              fontWeight: 400,
              color: '#2C2C2C',
              letterSpacing: '-0.5px',
              mb: 2
            }}
          >
            Chuck Downing
          </Typography>
          
          <Typography 
            variant="h2" 
            sx={{ 
              fontSize: { xs: '1.25rem', sm: '1.5rem' },
              fontWeight: 400,
              color: '#4A4A4A',
              letterSpacing: '0.2px',
              mb: 4,
              lineHeight: 1.4
            }}
          >
            Fourth Year Accounting PhD Student at MIT Sloan
          </Typography>
          
          <Typography 
            variant="body1"
            sx={{ 
              fontSize: '1.125rem',
              lineHeight: 1.7,
              color: '#4A4A4A',
              mb: 3,
              letterSpacing: '0.2px'
            }}
          >
            Welcome! I'm an accounting PhD student interested in information economics in the digital economy. 
            I am particularly interested in the economic consequences of generative AI in driving changes in firm disclosures, information processing, and the accounting labor market.
          </Typography>
          
          <Typography 
            variant="body1"
            sx={{ 
              fontSize: '1.125rem',
              lineHeight: 1.7,
              color: '#4A4A4A',
              letterSpacing: '0.2px'
            }}
          >
            I am currently pursuing my doctorate at MIT Sloan.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Home;